import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
  Alert,
  Badge,
  Collapse,
  InputGroup,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Button,
  Col,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CustomInput,
} from "reactstrap";
import {
  BLACK,
  DARK_GRAY,
  GRAY,
  GREEN,
  RECIPE_STATUS,
  USER_RIGHTS,
} from "../../../constant/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faChevronRight,
  faChevronDown,
  faCopy,
  faEdit,
  faStop,
  faTrashAlt,
  faPlus,
  faInfinity,
  faSave,
  faTimes,
  faLock,
  faUnlock,
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
import image from "../../../assets/images/icon/image.png";
import "../../../styles/ingredients.scss";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import _ from "lodash";
import CurrencyInput from "react-currency-input-field";

import commonService from "../../../services/common.service";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import RecipeLocationTreeCardWrapper from "./RecipeLocationTreeCardWrapper";
import ItemReplacementCard from "./itemReplacementCard";
import RecipeIngredientsModal from "../recipe/recipe-ingredients-modal";
import { allRecipeRequest } from "../../../redux/actions/recipe.action";
import { SupplierHelper } from "../../../helpers/supplierHelper";
import { RecipeHelper } from "../../../helpers/recipeHelper";
import { getConversionFactor } from "../../../services/units.service";
import { RESTAURANT_REQUEST } from "../../../config/api.config";
import handleResponse from "../../../services/response.service";
import {
  recipeContainingRecipes,
  recipeSupplierOfferings,
  replaceIngredient,
} from "../../../services/recipe.service";
import RecipeServices from "../../../services/recipe.service";
import { OpenConfirmModal } from "../../card-components/confirm-card/confirm-modal";
import PrintLabelModal from "../../printer/print-label-modal";

class ItemCard extends Component<any, any> {
  otherRecipeSet: boolean = false;
  constructor(props: any) {
    super(props);
    this.state = {
      activeTab: "details",
      collapse: false,
      isCostEditEnabled: false,
      cost: 0,
      replacementRecipe: "",
      openItemReplaceModal: false,
      openLabelPrintModal: false,
      openReplacingRecipeModal: false,
      otherRecipesLists: [],
      replacingRecipesLists: [],
      conversionFactor: 0,
      costList: [],
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps?.isUpdated !== this.props.isUpdated) {
      this.setState({
        isCostEditEnabled: false,
      });
    }
    if (this.props.updateList) {
      this.setState({
        collapse: false,
        activeTab: "details",
      });
    }
    if (
      !this.otherRecipeSet &&
      this.props.allrecipes?.length > 0 &&
      this.state.supplierList
    ) {
      this.setOtherRecipesList();
    }
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  onCardClick = (index: any) => {
    this.setState({
      activeCard: index,
    });
  };

  onEditClick = () => {
    this.setState({
      isCostEditEnabled: !this.state.isCostEditEnabled,
      cost: commonService.displayDigitalAmount(this.props?.cost),
    });
  };

  handleChange = (value: any) => {
    if (value >= 0 || !value) {
      this.setState({
        cost: value,
      });
    }
  };

  handleSave = () => {
    let minorUnit = JSON.parse(
      localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
    )?.minor_units;
    this.props?.handleCostUpdate(
      (this.state.cost * Math.pow(10, minorUnit)).toFixed(minorUnit),
      this.props.itemData.uuid
    );
  };

  initItemReplacementModal = () => {
    this.props.getAllRecipe({
      restaurantuuid: this.props.match.params.uuid,
    });
    SupplierHelper.getSuppliers(
      this.props.match.params.uuid,
      this.setSupplierList.bind(this)
    );
    this.toggleItemReplacementModal();
  };

  allowOrder = () => {
    return (
      this.props.itemData.supplier_uuid &&
      (!this.props.itemData?.imported_from_id ||
        (this.props.itemData?.imported_from_id &&
          this.props.importedItems[this.props.itemData?.uuid]?.isSupplied ===
            true &&
          this.props.importedItems[this.props.itemData?.uuid]?.supplierOffering
            ?.modified === false))
    );
  };

  getCostList = () => {
    if (
      this.props.itemData.supplier_uuid &&
      this.props.itemData?.imported_from_id
    ) {
      const allUnits = _.isEmpty(this.props.units) ? [] : this.props.units;
      const payLoad = {
        credentials: {
          recipeuuid: this.props.itemData.uuid,
          restaurantuuid: this.props.match.params.uuid,
        },
      };
      recipeSupplierOfferings(RESTAURANT_REQUEST, payLoad)
        .then((data: any) => {
          const responseData: any = handleResponse(data);
          if (responseData.ok && data?.data.length > 0) {
            const costList: any = data.data.map((offering: any) => {
              const offeringUnit = allUnits.find(
                (u: any) => u.uuid === offering.offered_unit_uuid
              );
              return {
                cost: commonService.displayDigitalAmount(offering.price),
                unit: commonService.applyLocalization(
                  "restaurant",
                  "name",
                  offeringUnit?.locales
                )["name"],
              };
            });
            this.setState({
              costList: costList,
            });
          }
        })
        .catch((error: any) => {});
    }
  };

  handleArchive = (e: any) => {
    const status =
      this.props.itemData.status !== RECIPE_STATUS.ARCHIVED
        ? RECIPE_STATUS.ARCHIVED
        : RECIPE_STATUS.PUBLISHED;
    OpenConfirmModal({
      title:
        status === RECIPE_STATUS.ARCHIVED
          ? this.props.t("questions.archive")
          : this.props.t("questions.restore"),
    }).then((accepted) => {
      this.updateRecipeStatus(status);
    });
  };

  updateRecipeStatus = (status: number) => {
    this.setState({ isFetching: true });
    RecipeServices.publishRecipe(RESTAURANT_REQUEST, {
      credentials: {
        uuid: this.props.itemData.uuid,
        restaurantuuid: this.props.match.params.uuid,
      },
      data: {
        status: status,
      },
    })
      .then((response: any) => {
        this.setState({ isFetching: false });
        if (response.status === 200) {
          commonService.toastService(response.data.flash, "success");
          this.props.fetchData();
        } else {
          commonService.toastService(response.data.flash, "danger");
        }
      })
      .catch((error) => {
        this.setState({ isFetching: false });
        commonService.toastService("", "danger", JSON.stringify(error.error));
      });
  };

  setSupplierList = (supplierList: any) => {
    this.setState({
      supplierList: supplierList,
    });
  };

  setOtherRecipesList = () => {
    this.setState({
      otherRecipesLists: RecipeHelper.generateOtherRecipeList(
        this.props.allrecipes,
        this.state.supplierList,
        this.props.itemData.uuid
      ),
    });
    this.otherRecipeSet = true;
  };

  toggleItemReplacementModal = () => {
    this.setState({
      openItemReplaceModal: !this.state.openItemReplaceModal,
    });
  };

  toggleLabelPrintModal = () => {
    this.setState({
      openLabelPrintModal: !this.state.openLabelPrintModal,
    });
  };

  checkReplacementUnitConversion = (option: any): void => {
    this.setState({
      replacementRecipe: option.uuid,
    });
    const payLoad = {
      credentials: {
        restaurantuuid: this.props.match.params.uuid,
        data: {
          recipe_uuid: this.props.itemData.uuid,
          from_unit: this.props.itemData.output_unit_uuid,
          to_unit: option.output_unit_uuid,
        },
      },
    };

    getConversionFactor(RESTAURANT_REQUEST, payLoad)
      .then((response: any) => {
        if (!!response.status) {
          this.handleUnitConversionFactor(response);
        } else {
          handleResponse(response);
        }
      })
      .catch((error: any) => {
        commonService.toastService("", "danger", JSON.stringify(error.error));
      });
  };

  handleUnitConversionFactor = (response: any): void => {
    if (response.data.data.conversion_factor !== 0) {
      this.setState({
        conversionFactor: response.data.data.conversion_factor,
      });
    } else {
      commonService.toastService(
        this.props.t("error.unitConversionDoesNotExist"),
        "danger"
      );
      return;
    }

    this.buildReplaceItemsList();
  };

  buildReplaceItemsList = (): void => {
    this.setState({
      replacingRecipesLists: [],
    });
    const payLoad = {
      credentials: {
        recipeuuid: this.props.itemData.uuid,
        restaurantuuid: this.props.match.params.uuid,
      },
    };

    recipeContainingRecipes(RESTAURANT_REQUEST, payLoad)
      .then((data: any) => {
        const responseData: any = handleResponse(data);
        if (responseData.ok) {
          this.setState({
            replacingRecipesLists: RecipeHelper.generateOtherRecipeList(
              data.data,
              this.state.supplierList,
              this.props.itemData.uuid,
              [RECIPE_STATUS.PUBLISHED, RECIPE_STATUS.DRAFT]
            ),
          });
          this.toggleReplacingRecipeModal();
        } else {
          commonService.toastService(this.props.t("failed"), "danger");
        }
      })
      .catch((error: any) =>
        commonService.toastService(this.props.t("failed"), "danger")
      );
  };

  toggleReplacingRecipeModal = () => {
    this.setState({
      openReplacingRecipeModal: !this.state.openReplacingRecipeModal,
    });
  };

  saveIngredientReplacement = (data: Array<any>) => {
    const payLoad = {
      credentials: {
        restaurantuuid: this.props.match.params.uuid,
        recipeuuid: this.props.itemData.uuid,
        data: {
          replaceBy: this.state.replacementRecipe,
          replaceIn: data?.flatMap((item) => item.uuid),
          conversionFactor: this.state.conversionFactor,
        },
      },
    };
    replaceIngredient(RESTAURANT_REQUEST, payLoad)
      .then((response: any) => {
        handleResponse(response);
      })
      .catch((error: any) =>
        commonService.toastService(this.props.t("failed"), "danger")
      );
  };

  renderCardActionButtons = () => {
    const { t } = this.props;
    return (
      <>
        {this.props?.hasCopy && (
          <span
            onClick={() => {
              this.props.onItemCopy(this.props.itemData.uuid);
            }}
            className="pointer-cursor"
            data-toggle="tooltip"
            data-placement="left"
            title={t("common.copy")}
          >
            <FontAwesomeIcon
              style={{ color: GREEN, margin: 5 }}
              icon={faCopy}
            />
          </span>
        )}
        <span
          onClick={() => {
            this.props.onItemEdit(this.props.itemData.uuid);
          }}
          className="pointer-cursor"
          data-toggle="tooltip"
          data-placement="left"
          title={t("common.edit")}
        >
          <FontAwesomeIcon style={{ color: GREEN, margin: 5 }} icon={faEdit} />
        </span>
        <span
          onClick={() => {
            this.props.onItemDelete(this.props.itemData.uuid, "choiceItem");
          }}
          className="pointer-cursor"
          data-toggle="tooltip"
          data-placement="left"
          title={t("common.delete")}
        >
          <FontAwesomeIcon
            style={{ color: GRAY, margin: 5 }}
            icon={faTrashAlt}
          />
        </span>
        {this.allowOrder() && (
          <Button
            color="info"
            className="my-2 mx-2 mt-2 px-2 py-1 h-75"
            size="sm"
            onClick={() => {
              this.props.openOrderModal(this.props.itemData);
            }}
          >
            {t("common.order")}
          </Button>
        )}
        <UncontrolledDropdown>
          <DropdownToggle tag="a" className="pointer-cursor">
            <FontAwesomeIcon
              style={{
                color: "#3f3f3f",
                marginTop: 15,
                marginLeft: 5,
              }}
              icon={faEllipsisV}
            />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem tag="a" onClick={this.toggleLabelPrintModal}>
              {t("common.printLabel")}
            </DropdownItem>
            <DropdownItem tag="a" onClick={this.initItemReplacementModal}>
              {t("common.replaceItem")}
            </DropdownItem>
            <DropdownItem tag="a" onClick={this.handleArchive}>
              {this.props.itemData?.status === RECIPE_STATUS.ARCHIVED
                ? t("common.restore")
                : t("common.archive")}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>

        <RecipeIngredientsModal
          title="common.selectReplacementIngredient"
          allIngredients={this.state.otherRecipesLists}
          isOpen={this.state.openItemReplaceModal}
          labelOptions={this.props.labelOptions}
          saveRecipeSelection={this.checkReplacementUnitConversion.bind(this)}
          toggleModal={this.toggleItemReplacementModal}
        />

        <PrintLabelModal
          title="printer.selectPrinterTemplate"
          printerTemplates={this.props.printerTemplates}
          isOpen={this.state.openLabelPrintModal}
          toggleModal={this.toggleLabelPrintModal}
          printPage={`/restaurant/${this.props.match.params.uuid}/recipe/${this.props.itemData.uuid}/print-label/`}
        />

        <RecipeIngredientsModal
          title="common.selectItemsToReplace"
          allIngredients={this.state.replacingRecipesLists}
          isOpen={this.state.openReplacingRecipeModal}
          labelOptions={this.props.labelOptions}
          saveRecipeSelection={this.saveIngredientReplacement.bind(this)}
          toggleModal={this.toggleReplacingRecipeModal}
          multiSelect={true}
          hideArticles={true}
        />
      </>
    );
  };

  renderSupplierInventoryInfo = () => {
    if (
      !_.isEmpty(this.props?.supplierInventoryInfo) &&
      this.props?.supplierInventoryInfo[this.props.itemData.uuid]
    ) {
      return (
        <div>
          <small className="pr-2">
            {
              this.props?.supplierInventoryInfo[this.props.itemData.uuid][
                "quantity"
              ]
            }{" "}
            {
              this.props?.supplierInventoryInfo[this.props.itemData.uuid][
                "outputUnit"
              ]
            }{" "}
            {this.props.t("common.onstock")}
          </small>
        </div>
      );
    }
  };

  renderCardActionDropDownItems = () => {
    const { t } = this.props;
    return (
      <>
        {this.props?.hasCopy && (
          <DropdownItem
            tag="a"
            onClick={() => this.props.onItemCopy(this.props.itemData.uuid)}
          >
            {t("common.copy")}
          </DropdownItem>
        )}
        <DropdownItem
          tag="a"
          onClick={() => this.props.onItemEdit(this.props.itemData.uuid)}
        >
          {t("common.edit")}
        </DropdownItem>
        <DropdownItem
          tag="a"
          onClick={() => this.props.onItemDelete(this.props.itemData.uuid)}
        >
          {t("common.delete")}
        </DropdownItem>
        {this.props.itemData.supplier_uuid && (
          <DropdownItem
            tag="a"
            onClick={() => {
              this.props.openOrderModal(this.props.itemData);
            }}
          >
            {t("common.order")}
          </DropdownItem>
        )}
      </>
    );
  };

  renderTagValue = (tagData: any) => {
    let value: any;
    if (tagData?.tag.meta.type === 4) {
      const [assignmentVal] = this.props.contentList.filter(
        (content: any) => content.value === tagData.recipe_tag_meta.value
      );
      value = (
        <>
          <em>{assignmentVal?.label}</em>
        </>
      );
    } else if (tagData?.tag.meta.type !== 2) {
      value = tagData?.recipe_tag_meta?.default;
    } else {
      value = (
        <>
          <CustomInput
            id={`${tagData?.tag?.key}-value`}
            type="checkbox"
            name={`${tagData?.tag?.key}-value`}
            defaultChecked={tagData?.recipe_tag_meta?.default}
            disabled={true}
          />
        </>
      );
    }
    return value;
  };

  setKey = (key: any) => {
    this.setState({
      activeTab: key,
    });

    this.props.handleSelect(key, this.props.itemData.uuid);
  };

  getItemUnitSymbol = (itemData: any) => {
    const itemUnitUuid = this.props.isSupplierOffering
      ? itemData.offered_unit
      : itemData.output_unit_uuid;
    const allUnits = _.isEmpty(this.props.units) ? [] : this.props.units;
    const itemUnit = allUnits.find((u: any) => u.uuid === itemUnitUuid);
    return (
      itemUnit && (
        <span>
          (
          {
            commonService.applyLocalization(
              "restaurant",
              "name",
              itemUnit?.locales
            )["symbol"]
          }
          )
        </span>
      )
    );
  };

  render() {
    const { t } = this.props;
    let itemUnits: any = undefined;
    let itemTags: any = undefined;

    if (this.props?.units) {
      itemUnits =
        Object.keys(this.props.units).length > 0
          ? this.props?.units.filter(
              (unit: any) =>
                unit.recipe_uuid === this.props.itemData.uuid ||
                unit.uuid === this.props.itemData.output_unit_uuid
            )
          : [];
    }

    if (this.props?.tags) {
      const tags: Array<any> = this.props?.itemDetails?.tags?.map(
        (tag: any) => {
          tag.name = commonService.applyLocalization(
            "restaurant",
            "name",
            tag.tag.locales
          )["name"];

          return tag;
        }
      );
      itemTags = _.sortBy(tags, "name");
    }
    const itemLocale = commonService.applyLocalization(
      "restaurant",
      "name",
      this.props.itemData.locales
    );

    const restDtls: any =
      localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "";
    const restaurantDetail = JSON.parse(restDtls);
    const restaurantLanguage: any = localStorage.getItem("i18nextLng");

    const minorUnit = JSON.parse(
      localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
    )?.minor_units;

    const currencyCode = JSON.parse(
      localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
    )?.currency_code;

    const label_uuids = Array.isArray(this.props.itemData?.label_uuids)
      ? this.props.itemData?.label_uuids
      : this.props.itemData?.label_uuids?.split(",");

    const locationTreeActions: any = {
      addNewStorage: this.props.addNewStorage,
      editStorage: this.props.editStorage,
      editKeyValue: this.props.editKeyValue,
      getBestMatchUnits: this.props?.getBestMatchUnits,
      removeStorage: this.props.removeStorage,
    };
    const relatedUnits =
      commonService.getRelateUnitDropdowns(
        this.props.itemData?.output_unit_uuid,
        this.props?.units,
        this.props?.units,
        1,
        this.props.itemData?.uuid
      ) || [];

    const supplierDetails: any =
      this.props?.area === "ingredient" &&
      this.props?.getSupplierDetails(this.props.itemData?.supplier_uuid);
    return (
      // TODO SR-416: Need to Merge all duplicate components

      <section className="bf-service-item-collapse border rounded mb-3">
        <header className="d-flex align-items-center justify-content-between w-100">
          <div
            className="col-left d-flex align-items-center  px-3 py-2 cursor-pointer"
            onClick={this.toggle}
          >
            {!this.props?.isDetailPage && (
              <FontAwesomeIcon
                className="bf-accordion-arrow mr-2"
                icon={this.state.collapse ? faChevronDown : faChevronRight}
              />
            )}
            <figure className="img-block ml-1 m-0">
              <img
                onClick={() => {
                  !this.props.isSupplierOffering &&
                    this.props.setImageEdit(this.props.itemData);
                }}
                data-toggle="tooltip"
                data-placement="left"
                title="Image Upload"
                className={[
                  this.props.type === "recipe"
                    ? this.props.isSupplierOffering
                      ? "disbale-checkbox lazyload"
                      : "cursor-pointer lazyload"
                    : "lazyload",
                  "d-block",
                ].join(" ")}
                data-src={
                  this.props.itemData.image_url
                    ? this.props.itemData.image_url
                    : image
                }
                alt=""
              />
            </figure>

            <FontAwesomeIcon
              className="color-box-one"
              style={{
                color: `${this.props.itemData.color}`,
                fontSize: 22,
              }}
              icon={faStop}
            />
            <h6 className="m-0" style={{ color: DARK_GRAY, paddingLeft: 10 }}>
              <div>
                {itemLocale.name} {this.getItemUnitSymbol(this.props.itemData)}
              </div>

              <small> {this.props.itemData?.created_on} </small>
              {supplierDetails && <small> | {supplierDetails}</small>}
              {this.props.importedItems &&
                this.props.itemData?.imported_from_id && (
                  <ItemReplacementCard
                    itemData={this.props.itemData}
                    importedItems={this.props.importedItems}
                    itemDeleteHandler={this.props.onItemDelete}
                    fetchData={this.props.fetchData}
                  />
                )}
            </h6>
            {!!this.props?.showItemStatus && (
              <span className="ml-2">
                {this.props.renderStatusBadge(
                  this.props.itemData.status,
                  this.props.itemData.drafts
                )}
              </span>
            )}
          </div>

          {!this.props.isSupplierOffering ? (
            <div className="col-right d-flex align-items-center mr-2">
              <UncontrolledDropdown className="m-0 d-sm-none" direction="left">
                <DropdownToggle color="transparent" className="p-0">
                  <FontAwesomeIcon
                    style={{ color: GREEN }}
                    icon={faEllipsisV}
                  />
                </DropdownToggle>
                <DropdownMenu>
                  {this.renderCardActionDropDownItems()}
                </DropdownMenu>
              </UncontrolledDropdown>
              <span className="d-none d-sm-flex  align-items-center">
                {this.renderSupplierInventoryInfo()}
                {this.renderCardActionButtons()}
              </span>
            </div>
          ) : (
            <div className="col-right d-flex align-items-center mr-2">
              {commonService.hasPermissionToAccess(
                USER_RIGHTS.VIEW_ITEM_PRICES,
                this.props.match.params.uuid
              ) && (
                <span className="b-ingredient-price text-left">
                  {this.props.t("common.price")}:{" "}
                  {commonService.displayDigitalAmount(
                    this.props.itemData.price ? this.props.itemData.price : 0
                  )}{" "}
                  {restaurantDetail?.currency_code}
                </span>
              )}
              <div className="d-flex">
                {!commonService.hasOnlyPermission(
                  USER_RIGHTS.SUPPLIER_OFFERING_ONLY,
                  this.props.match.params.uuid
                ) && (
                  <Button
                    color="info"
                    className="my-2 px-2 py-1 h-75"
                    size="sm"
                    onClick={() => {
                      this.props.handleIngredientImport(this.props.itemData);
                    }}
                  >
                    {t("common.add")}
                  </Button>
                )}
                <Button
                  color="info"
                  className="my-2 mx-2 mt-2 px-2 py-1 h-75"
                  size="sm"
                  onClick={() => {
                    this.props.openOrderModal(this.props.itemData);
                  }}
                >
                  {t("common.order")}
                </Button>
              </div>
            </div>
          )}
        </header>
        <Collapse isOpen={this.state.collapse || this.props?.isDetailPage}>
          <div className="recipe-dtls p-0 row m-0">
            <div className="col-12 col-sm p-3">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={
                      this.state.activeTab === "details"
                        ? "active"
                        : "" + " b-cursor-p"
                    }
                    onClick={() => {
                      this.setKey("details");
                    }}
                  >
                    {t("common.details")}
                  </NavLink>
                </NavItem>
                {this.props.type === "newIngredientList" && (
                  <NavLink
                    className={
                      this.state.activeTab === "storage"
                        ? "active"
                        : "" + " b-cursor-p"
                    }
                    onClick={() => {
                      this.setKey("storage");
                    }}
                  >
                    {t("common.location")}
                  </NavLink>
                )}
                <NavLink
                  className={
                    this.state.activeTab === "units"
                      ? "active"
                      : "" + " b-cursor-p"
                  }
                  onClick={() => {
                    this.setKey("units");
                  }}
                >
                  {t("common.units")}
                </NavLink>
                {this.props.hasTag && (
                  <NavLink
                    className={
                      this.state.activeTab === "tags"
                        ? "active"
                        : "" + " b-cursor-p"
                    }
                    onClick={() => {
                      this.setKey("tags");
                    }}
                  >
                    {t("tag.tags")}
                  </NavLink>
                )}
                {!this.props.isSupplierOffering && (
                  <NavLink
                    className={
                      this.state.activeTab === "cost"
                        ? "active"
                        : "" + " b-cursor-p"
                    }
                    onClick={() => {
                      this.getCostList();
                      this.setKey("cost");
                    }}
                  >
                    {t("common.cost")}
                  </NavLink>
                )}
                {this.props.ingredient?.status !== 1 &&
                  (this.props.area === "ingredient" ||
                    this.props.area === "recipe" ||
                    this.props.area === "recipeDetails") &&
                  typeof this.props?.recipeSubstitutes !== "undefined" && (
                    <NavLink
                      className={
                        this.state.activeTab === "substitutes"
                          ? "active"
                          : "" + " b-cursor-p"
                      }
                      onClick={() => {
                        this.setKey("substitutes");
                      }}
                    >
                      {t("substituteGroup.substituteGroups")}
                    </NavLink>
                  )}
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="details">
                  <div className="recipe-description mt-3 mb-3">
                    {itemLocale?.description}
                    {this.props?.extraDetails}

                    {supplierDetails && (
                      <Row>
                        <Col lg={3} sm={6}>
                          {t("supplier.supplier")}{" "}
                        </Col>
                        <Col lg={6} sm={6}>
                          {supplierDetails}
                        </Col>
                      </Row>
                    )}
                  </div>

                  <div className="recipe-categories">
                    {label_uuids?.map((parent: any) => {
                      return this.labelName(parent);
                    })}
                  </div>
                  {this.props.hasDetails && (
                    <Row className="justify-content-end p-3">
                      <Button
                        className="btn btn-info"
                        onClick={() => {
                          this.props.onMoveToDetailPage(
                            this.props.itemData.uuid
                          );
                        }}
                      >
                        {t("common.viewMoreDetails")}
                      </Button>
                    </Row>
                  )}
                </TabPane>
                {/* TODO need to check this still uses */}

                {this.props.type === "newIngredientList" && (
                  <TabPane tabId="storage">
                    <RecipeLocationTreeCardWrapper
                      actions={locationTreeActions}
                      allLocations={this.props.allRooms}
                      recipeId={this.props.itemData.uuid}
                      savedLocations={this.props.storages}
                    />
                  </TabPane>
                )}
                {itemUnits !== undefined && (
                  <TabPane tabId="units">
                    <div className="recipe-description mt-2 mb-3">
                      {itemUnits.length === 0 ? (
                        <div className="d-flex align-items-center mt-3 ">
                          {t("unit.noUnitsAdded")}
                        </div>
                      ) : (
                        <>
                          <Label className="d-flex align-center font-weight-bold mt-2">
                            {t("unit.units")} :
                          </Label>
                          {this.props.unitCards(
                            itemUnits,
                            this.props.itemData.uuid
                          )}
                        </>
                      )}
                    </div>
                  </TabPane>
                )}
                {this.props.hasTag && (
                  <TabPane tabId="tags">
                    <div className="recipe-description mt-2 mb-3">
                      {itemTags && itemTags?.length > 0 ? (
                        <>
                          <div>
                            <Label
                              className="d-flex align-center font-weight-bold mt-2"
                              onClick={this.props.addTagModal.bind(this)}
                            >
                              {this.props.isSupplierOffering
                                ? itemTags && itemTags?.length > 0
                                  ? t("tag.tags")
                                  : ""
                                : t("tag.tags")}
                              {!this.props.isSupplierOffering && (
                                <Button
                                  className="mx-2 p-0 px-1"
                                  size="sm"
                                  color="outline-primary"
                                >
                                  <FontAwesomeIcon icon={faPlus} />
                                </Button>
                              )}
                            </Label>
                          </div>

                          {itemTags && itemTags?.length > 0 && (
                            <Table
                              responsive
                              className="table-striped text-nowrap"
                            >
                              <thead>
                                <tr>
                                  <th>{t("common.name")}</th>
                                  <th>{t("common.value")}</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {itemTags?.map((tag: any, index: any) => (
                                  <tr key={index}>
                                    <td>
                                      <div className="align-items-center d-flex w-100 h-100">
                                        {tag.recipe_tag_meta.override ? (
                                          <FontAwesomeIcon
                                            style={{
                                              color: GREEN,
                                            }}
                                            icon={faUnlock}
                                            className="mr-1"
                                          />
                                        ) : (
                                          <FontAwesomeIcon
                                            style={{
                                              color: GRAY,
                                            }}
                                            icon={faLock}
                                            className="mr-1"
                                          />
                                        )}
                                        <span>
                                          {
                                            commonService.applyLocalization(
                                              "restaurant",
                                              "name",
                                              tag.tag.locales
                                            )["name"]
                                          }
                                        </span>
                                      </div>
                                    </td>
                                    <td>{this.renderTagValue(tag)}</td>

                                    <td className="text-right">
                                      {!this.props.isSupplierOffering && (
                                        <>
                                          {" "}
                                          <span
                                            onClick={() => {
                                              this.props.updateTagModel(
                                                tag,
                                                this.props.itemData.uuid
                                              );
                                            }}
                                            className="pointer-cursor"
                                            data-toggle="tooltip"
                                            data-placement="left"
                                            title={t("common.edit")}
                                          >
                                            <FontAwesomeIcon
                                              style={{
                                                color: GREEN,
                                              }}
                                              icon={faEdit}
                                              className="m-1"
                                            />
                                          </span>
                                          <span
                                            onClick={() => {
                                              this.props.onDeleteClick(
                                                "tag",
                                                tag.tag.uuid,
                                                this.props.itemData.uuid
                                              );
                                            }}
                                            className="pointer-cursor"
                                            data-toggle="tooltip"
                                            data-placement="left"
                                            title={t("common.delete")}
                                          >
                                            <FontAwesomeIcon
                                              style={{
                                                color: GRAY,
                                              }}
                                              className="m-1"
                                              icon={faTrashAlt}
                                            />
                                          </span>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          )}
                        </>
                      ) : (
                        <div>
                          {!this.props.isSupplierOffering && (
                            <Label
                              className="d-flex align-center font-weight-bold mt-2"
                              onClick={this.props.addTagModal.bind(this)}
                            >
                              {t("tag.tags")} :
                              <Button
                                className="mx-2 p-0 px-1"
                                size="sm"
                                color="outline-primary"
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </Button>
                            </Label>
                          )}
                        </div>
                      )}
                    </div>
                  </TabPane>
                )}
                {!this.props.isSupplierOffering && (
                  <TabPane tabId="cost">
                    <Row>
                      {this.props.type === "newIngredientList" &&
                        commonService.hasPermissionToAccess(
                          USER_RIGHTS.VIEW_ITEM_PRICES,
                          this.props.match.params.uuid
                        ) && (
                          <Col md={5}>
                            <div className="recipe-description mt-2 mb-3">
                              {!!this.props.cost?.invalidTree && (
                                <Alert color="warning">
                                  <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                    className="mr-2"
                                  />
                                  {this.props.t(
                                    "ingredient.ingredientCostInvalid"
                                  )}
                                </Alert>
                              )}

                              <Row className="align-items-center">
                                {(this.props?.area === "recipe" ||
                                  this.props?.area === "recipeDetails") && (
                                  <>
                                    <Col sm={5} className="text-sm-right">
                                      {t("ingredient.ingredientCost")}
                                    </Col>
                                  </>
                                )}
                                {this.props?.area === "ingredient" && (
                                  <Col sm={5} className="text-sm-right">
                                    {t("common.cost")}
                                  </Col>
                                )}
                                <Col
                                  sm={7}
                                  className={
                                    _.isEmpty(this.state.costList)
                                      ? "d-flex align-items-center"
                                      : ""
                                  }
                                >
                                  {this.props?.area !== "ingredient" && (
                                    <div>
                                      {!!this.props.cost?.invalidTree ? (
                                        <FontAwesomeIcon icon={faInfinity} />
                                      ) : (
                                        <>
                                          {this.props.cost?.ingredientCost
                                            ?.min ===
                                            this.props.cost?.ingredientCost
                                              ?.max &&
                                            this.props.cost?.ingredientCost
                                              ?.min && (
                                              <span>
                                                {commonService.displayDigitalAmount(
                                                  this.props.cost
                                                    ?.ingredientCost?.min
                                                )}
                                              </span>
                                            )}
                                          {this.props.cost?.ingredientCost
                                            ?.min !==
                                            this.props.cost?.ingredientCost
                                              ?.max &&
                                            this.props.cost?.ingredientCost
                                              ?.min && (
                                              <>
                                                <span>
                                                  {commonService.displayDigitalAmount(
                                                    this.props.cost
                                                      ?.ingredientCost?.min
                                                  )}
                                                </span>
                                                <span className="mx-2">-</span>
                                                <span>
                                                  {this.props.cost
                                                    ?.ingredientCost?.max &&
                                                    commonService.displayDigitalAmount(
                                                      this.props.cost
                                                        ?.ingredientCost?.max
                                                    )}
                                                </span>
                                              </>
                                            )}

                                          <span className="ml-2">
                                            {restaurantDetail?.currency_code}
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  )}
                                  {this.props?.area === "ingredient" &&
                                    !this.state.isCostEditEnabled &&
                                    _.isEmpty(this.state.costList) &&
                                    !this.props.itemData?.imported_from_id && (
                                      <>
                                        {!this.props.isFetching &&
                                          this.props.cost &&
                                          commonService.displayDigitalAmount(
                                            this.props.cost
                                          )}
                                        <span className="ml-2">
                                          {restaurantDetail?.currency_code}
                                        </span>
                                        <Button
                                          color="transparent"
                                          className="ml-4 p-1"
                                          onClick={() => this.onEditClick()}
                                        >
                                          <FontAwesomeIcon
                                            style={{
                                              color: GREEN,
                                              fontSize: 18,
                                            }}
                                            icon={faEdit}
                                          />
                                        </Button>
                                      </>
                                    )}
                                  {this.props?.area === "ingredient" &&
                                    !this.state.isCostEditEnabled &&
                                    !_.isEmpty(this.state.costList) && (
                                      <Table className="table-borderless m-0">
                                        {this.state.costList.map(
                                          (offering: any) => (
                                            <tr className="p-0">
                                              <td className="p-1">
                                                <Badge color="light">{`1${offering.unit}`}</Badge>
                                              </td>
                                              <td className="p-1">{`${offering.cost} ${restaurantDetail?.currency_code}`}</td>
                                            </tr>
                                          )
                                        )}
                                      </Table>
                                    )}
                                  {this.props?.area === "ingredient" &&
                                    this.state.isCostEditEnabled && (
                                      <InputGroup className="position-relative align-items-center">
                                        <span
                                          className="w-50"
                                          onClick={() => {
                                            if (
                                              String(this.state.cost) === "0" ||
                                              String(this.state.cost) === "0.00"
                                            ) {
                                              this.handleChange("");
                                            }
                                          }}
                                          onBlur={() => {
                                            if (this.state.cost === "") {
                                              this.handleChange("0");
                                            }
                                          }}
                                        >
                                          <CurrencyInput
                                            intlConfig={{
                                              locale: restaurantLanguage,
                                              currency: currencyCode,
                                            }}
                                            className="w-100"
                                            decimalScale={minorUnit}
                                            decimalsLimit={minorUnit}
                                            value={this.state.cost}
                                            onValueChange={this.handleChange}
                                            prefix=" "
                                          />
                                        </span>

                                        <span className="ml-2">
                                          {restaurantDetail?.currency_code}
                                        </span>
                                        <Button
                                          color="transparent"
                                          className="p-1 ml-2"
                                          onClick={() => this.handleSave()}
                                        >
                                          <FontAwesomeIcon
                                            style={{
                                              color: GREEN,
                                              fontSize: 18,
                                            }}
                                            icon={faSave}
                                          />
                                        </Button>
                                        <Button
                                          color="transparent"
                                          className="p-1"
                                          onClick={() => this.onEditClick()}
                                        >
                                          <FontAwesomeIcon
                                            style={{
                                              color: BLACK,
                                              fontSize: 18,
                                            }}
                                            icon={faTimes}
                                          />
                                        </Button>
                                      </InputGroup>
                                    )}
                                </Col>
                              </Row>
                              {(this.props?.area === "recipe" ||
                                this.props?.area === "recipeDetails") && (
                                <>
                                  <Row>
                                    <Col sm={5} className="text-sm-right">
                                      {t("common.cookingCost")}
                                    </Col>
                                    <Col
                                      sm={7}
                                      className="d-flex align-items-center"
                                    >
                                      {!!this.props.cost?.invalidTree ? (
                                        <FontAwesomeIcon icon={faInfinity} />
                                      ) : (
                                        <>
                                          {commonService.displayDigitalAmount(
                                            this.props.cost?.executionCost
                                          )}
                                          <span className="ml-2">
                                            {restaurantDetail?.currency_code}
                                          </span>
                                        </>
                                      )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={5} className="text-sm-right">
                                      {t("common.totalCost")}
                                    </Col>
                                    <Col
                                      sm={7}
                                      className="d-flex align-items-center"
                                    >
                                      {!!this.props.cost?.invalidTree ? (
                                        <FontAwesomeIcon icon={faInfinity} />
                                      ) : (
                                        <>
                                          {this.props.cost?.ingredientCost
                                            ?.min ===
                                            this.props.cost?.ingredientCost
                                              ?.max &&
                                            this.props.cost?.ingredientCost
                                              ?.min && (
                                              <span>
                                                {commonService.displayDigitalAmount(
                                                  this.props.cost
                                                    ?.ingredientCost?.min +
                                                    this.props.cost
                                                      ?.executionCost
                                                )}
                                              </span>
                                            )}
                                          {this.props.cost?.ingredientCost
                                            ?.min !==
                                            this.props.cost?.ingredientCost
                                              ?.max &&
                                            this.props.cost?.ingredientCost
                                              ?.min && (
                                              <>
                                                <span>
                                                  {commonService.displayDigitalAmount(
                                                    this.props.cost
                                                      ?.ingredientCost?.min +
                                                      this.props.cost
                                                        ?.executionCost
                                                  )}
                                                </span>
                                                <span className="mx-2">-</span>
                                                <span>
                                                  {this.props.cost
                                                    ?.ingredientCost?.max &&
                                                    commonService.displayDigitalAmount(
                                                      this.props.cost
                                                        ?.ingredientCost?.max +
                                                        this.props.cost
                                                          ?.executionCost
                                                    )}
                                                </span>
                                              </>
                                            )}
                                          <span className="ml-2">
                                            {restaurantDetail?.currency_code}
                                          </span>
                                        </>
                                      )}
                                    </Col>
                                  </Row>
                                  {this.props.cost?.depthExceeds && (
                                    <Row>
                                      {" "}
                                      <Col sm={12} lg={12} xl={12}>
                                        <span className="bf-font-size-12 b-color-red">
                                          {" "}
                                          {t("recipe.costWarning")}
                                        </span>
                                      </Col>
                                    </Row>
                                  )}
                                </>
                              )}
                            </div>
                          </Col>
                        )}
                      <Col md={7}>
                        {" "}
                        <Button
                          className="mx-2 p-0 px-1 mb-2"
                          size="sm"
                          color="outline-primary"
                          onClick={() =>
                            this.props.updateServiceSets({
                              itemId: this.props.itemData.uuid,
                              itemOutputUnitId:
                                this.props.itemData.output_unit_uuid,
                              itemOutputQuantity:
                                this.props.itemData.output_quantity,
                              type: "NEW",
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                        <Table responsive className="table-striped text-nowrap">
                          <thead>
                            <tr>
                              <th>{t("serviceSet.serviceSets")}</th>
                              <th>{t("common.offeredUnit")}</th>
                              <th>{t("common.price")}</th>
                              <th>{t("common.status")}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props?.recipeServiceSets?.length > 0 ? (
                              this.props.recipeServiceSets.map(
                                (serviceSet: any, index: any) => (
                                  <tr key={index}>
                                    <td>{serviceSet?.name}</td>
                                    <td>
                                      {
                                        relatedUnits?.find(
                                          (unit: any) =>
                                            unit.value ===
                                            serviceSet?.offered_unit
                                        )?.label
                                      }
                                    </td>
                                    <td>
                                      <span className="mr-2">
                                        {commonService.displayDigitalAmount(
                                          serviceSet?.price || 0
                                        )}
                                      </span>
                                      {serviceSet?.currency}
                                    </td>
                                    <td>
                                      {" "}
                                      {!!serviceSet?.status ? (
                                        <span>{t("common.active")}</span>
                                      ) : (
                                        <span>{t("common.inactive")}</span>
                                      )}
                                    </td>

                                    <td className="text-right">
                                      {!this.props.isSupplierOffering && (
                                        <>
                                          <span
                                            onClick={() =>
                                              this.props.updateServiceSets({
                                                itemId:
                                                  this.props.itemData.uuid,
                                                itemOutputUnitId:
                                                  this.props.itemData
                                                    .output_unit_uuid,
                                                itemOutputQuantity:
                                                  this.props.itemData
                                                    .output_quantity,
                                                serviceSet,
                                                type: "EDIT",
                                              })
                                            }
                                            className="pointer-cursor"
                                            data-toggle="tooltip"
                                            data-placement="left"
                                            title={t("common.edit")}
                                          >
                                            <FontAwesomeIcon
                                              style={{
                                                color: GREEN,
                                              }}
                                              icon={faEdit}
                                              className="m-1"
                                            />
                                          </span>
                                          <span
                                            onClick={() =>
                                              this.props.handleServiceSetDelete(
                                                serviceSet.uuid,
                                                this.props.itemData.uuid
                                              )
                                            }
                                            className="pointer-cursor"
                                            data-toggle="tooltip"
                                            data-placement="left"
                                            title={t("common.delete")}
                                          >
                                            <FontAwesomeIcon
                                              style={{
                                                color: GRAY,
                                              }}
                                              className="m-1"
                                              icon={faTrashAlt}
                                            />
                                          </span>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td colSpan={4}>{t("common.noRecords")}</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </TabPane>
                )}
                {this.props.ingredient?.status !== 1 &&
                  (this.props.area === "ingredient" ||
                    this.props.area === "recipe" ||
                    this.props.area === "recipeDetails") &&
                  typeof this.props?.recipeSubstitutes !== "undefined" && (
                    <TabPane tabId="substitutes">
                      <div className="recipe-description mt-2 mb-3">
                        {this.props.recipeSubstitutes &&
                        this.props.recipeSubstitutes?.length > 0 ? (
                          <>
                            <div>
                              <Label
                                className="d-flex align-center font-weight-bold mt-2"
                                onClick={() => {
                                  this.props.handleSubstituteModal(
                                    this.props.itemData.uuid
                                  );
                                }}
                              >
                                {t("substituteGroup.substituteGroups")} :
                                <Button
                                  className="mx-2 p-0 px-1"
                                  size="sm"
                                  color="outline-primary"
                                >
                                  <FontAwesomeIcon icon={faPlus} />
                                </Button>
                              </Label>
                            </div>
                            {this.props.recipeSubstitutes &&
                              this.props.recipeSubstitutes?.length > 0 && (
                                <Table
                                  responsive
                                  className="table-striped text-nowrap"
                                >
                                  <thead>
                                    <tr>
                                      <th>{t("common.name")}</th>
                                      <th>{t("common.quantity")}</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.props.recipeSubstitutes?.map(
                                      (item: any, index: any) => (
                                        <tr key={index}>
                                          <td>
                                            {
                                              commonService.applyLocalization(
                                                "restaurant",
                                                "name",
                                                item?.locales
                                              )["name"]
                                            }
                                          </td>
                                          <td>{item?.quantity}</td>

                                          <td className="text-right">
                                            <span
                                              onClick={() => {
                                                this.props.handleSubstituteModal(
                                                  this.props.itemData.uuid,
                                                  item
                                                );
                                              }}
                                              className="pointer-cursor"
                                              data-toggle="tooltip"
                                              data-placement="left"
                                              title={t("common.edit")}
                                            >
                                              <FontAwesomeIcon
                                                style={{
                                                  color: GREEN,
                                                }}
                                                icon={faEdit}
                                                className="m-1"
                                              />
                                            </span>

                                            <span
                                              onClick={() => {
                                                this.props.handleSubstituteDelete(
                                                  item.uuid,
                                                  this.props.itemData.uuid
                                                );
                                              }}
                                              className="pointer-cursor"
                                              data-toggle="tooltip"
                                              data-placement="left"
                                              title={t("common.delete")}
                                            >
                                              <FontAwesomeIcon
                                                style={{
                                                  color: GRAY,
                                                }}
                                                className="m-1"
                                                icon={faTrashAlt}
                                              />
                                            </span>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </Table>
                              )}
                          </>
                        ) : (
                          <div className="d-flex align-items-center mt-3 ">
                            {t("substituteGroup.noSubstitutesAssigned")}
                            <Button
                              className="ml-2 px-2 py-1"
                              color="primary"
                              size="sm"
                              onClick={() => {
                                this.props.handleSubstituteModal(
                                  this.props.itemData.uuid
                                );
                              }}
                            >
                              {t("common.assign")}
                            </Button>
                          </div>
                        )}
                      </div>
                    </TabPane>
                  )}
              </TabContent>
            </div>
          </div>
        </Collapse>
      </section>
    );
  }

  public labelName(uuid: any): any {
    let allLabels = this.props.labels.length ? this.props.labels : [];
    let name;
    if (!!allLabels) {
      name = allLabels.map((item: any, i: number) => {
        if (uuid === item.uuid) {
          return (
            <span
              key={i}
              style={{
                backgroundColor: item.color,
                color: commonService.getFontContrast(item.color),
              }}
              className="more-text label-text"
            >
              {
                commonService.applyLocalization(
                  "restaurant",
                  "name",
                  item?.locales
                ).name
              }
            </span>
          );
        }
      });
      return name;
    }
  }
  public getChildren = (data: any, area: string) => {
    if (area) {
      const allStorage = JSON.parse(JSON.stringify(this.props.allStorage));
      return allStorage?.filter(
        (storage: any) => storage.room_uuid === data.uuid
      );
    }
  };

  public getClasses = (childHasSelection: boolean, ItemSelected: boolean) => {
    let classes = "b_tree_label ";
    if (childHasSelection && !ItemSelected) {
      classes += "text-info";
    } else if (ItemSelected) {
      classes += "font-weight-bold";
    }
    return classes;
  };
}

const mapStateToProps: any = (state: any) => {
  let isFetching = state.allrecipe.isFetching;
  let failure = state.allrecipe.failure;
  return {
    isFetching: isFetching,
    failure: failure,
    allrecipes: state.allrecipe.data,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getAllRecipe: (credentials: any) => {
      dispatch(allRecipeRequest(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ItemCard))
);
